import { CHAIN_IDS } from 'utils/wagmi'
import HOME from 'views/Home'
import SwapLayout from 'views/Swap/SwapLayout'

const IndexPage = () => (
  <SwapLayout>
    <HOME />
  </SwapLayout>
)

IndexPage.chains = CHAIN_IDS
IndexPage.screen = true

export default IndexPage
